import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getTeamByIDUrl                       = (id) => `/api/admin/golfClub/getTeamByID/${id}`;

export const getTeamByID = async (id) => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getTeamByIDUrl(id),
      { headers: "" }
    );
    return response;
};