import { getAuthState } from "../../assets/scripts/login-util";
import { axiosPost, axiosGet, axiosDelete, axiosPatch } from "./axiosFunctions";

const getUnAssignedPlayersUrl                         = '/api/OwnerAccess/getUnAssignedPlayers';
const getCategoryUrl                                  = '/api/OwnerAccess/getCategory';
const getUnSoldPlayerUrl                              = '/api/OwnerAccess/getUnSoldPlayer';
const getSoldPlayerUrl                                = '/api/OwnerAccess/getSoldPlayer';
const getSoldPlayerByTeamIDUrl                       = (teamID) => `/api/OwnerAccess/getSoldPlayerByTeamID/${teamID}`;
const getTeamUrl                                      = '/api/OwnerAccess/getTeam';

export const getUnAssignedPlayers = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUnAssignedPlayersUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getCategory = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getCategoryUrl,
      { headers: "" }
    );
    return response;
};

export const getUnSoldPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getUnSoldPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSoldPlayer = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getSoldPlayerUrl,
        { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};

export const getSoldPlayerByTeamID = async (teamID) => {
  
    const response = await axiosGet(
        getSoldPlayerByTeamIDUrl(teamID),
        { headers: "" }
    );
    return response;
};

export const getTeam = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(() => { return }, 2000);
  
    const response = await axiosGet(
        getTeamUrl,
      { headers: { auth_token: `bearer ${token}` } }
    );
    return response;
};