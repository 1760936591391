import React, { useEffect, useState }  from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { getCategory, getSoldPlayerByTeamID } from '../components/api/playerAPI';
import swal from 'sweetalert2'
import { getTeamByID } from '../components/api/myTeamAPI';
import Loading from '../components/global/loading';
import * as helper from '../components/global/helper';

const Team = () => {

    const [category,                setCategory]                = useState([]);
    const [selectedCategory,        setSelectedCategory]        = useState(0);
    const [searchString,            setSearchString]            = useState('');
    const [filteredPlayerList,      setFilteredPlayerList]      = useState([])
    const [playerList,              setPlayerList]              = useState([]);
    const [loading,                 setLoading]                 = useState(false);
    const [fund,                    setFund]                    = useState(0)
    const [team,                    setTeam]                    = useState(null)

    const { id } = useParams();

    useEffect(() => {
        fetchCategory();
        fetchTeamByID(id)
        fetchtSoldPlayerByTeamID(id);
    }, [id])

    useEffect(() => {
        if (playerList.length > 0)
            filterPlayer();
        else
            setFilteredPlayerList([]);
            
    }, [playerList, searchString, selectedCategory]);

    const filterPlayer = async () => {

        let reports = playerList;
        if (selectedCategory && selectedCategory != 0) reports = reports.filter(f => f.CategoryID == selectedCategory);
        if (searchString === '') {
            setFilteredPlayerList(reports);
        }else{
            const matchedPolicies = reports.filter((p) => {
                let matchString = `${p.FirstName} ${p.LastName} ${p.MemberShipNo} ${p.CategoryName} ${p.Email}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPlayerList(matchedPolicies);
        }
    };

    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            setCategory(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }
    const fetchTeamByID = async(id) => {
        try {
            const response = await getTeamByID(id)
            setTeam(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchtSoldPlayerByTeamID = async (id) => {
        try {
            setLoading(true);
            const { auctionPlayer, fund } = await getSoldPlayerByTeamID(id);
            setFund(fund)
            setPlayerList(auctionPlayer);             
            setLoading(false);
        } catch (error) {
            setLoading(false);
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
            <div className="kt-portlet kt-portlet--mobile" >
                <div className="kt-portlet__body kt-portlet__body--fit">
                    <div className="kt-portlet margin-b0">
                        <div className="kt-portlet__body">
                            <div className="kt-widget kt-widget--user-profile-3">
                                {team &&
                                    <div className="kt-widget__top">
                                        {team.Logo ? (
                                            <img className="kt-widget__pic kt-widget__pic" src={team.Logo} alt="user-profile"/>
                                        ) : (

                                            <div className="kt-widget__pic kt-widget__pic--brand kt-font-brand kt-font-boldest">
                                                {team.Name && team.Name.slice(0,1)}
                                            </div>
                                        )}
                                        <div className="kt-widget__content">
                                            <div className="row align-items-center">
                                                <div className="col-5">
                                                    <div className="kt-widget__head">
                                                        <span className="kt-widget__username">
                                                            {team.Name}
                                                        </span>
                                                        <div className="kt-portlet__head-toolbar">
                                                            <div className="kt-portlet__head-wrapper">
                                                                <div className="kt-portlet__head-actions">
                                                                    <div className = "kt-portlet__head_subhead" >

                                                                        <span className="margin-r10">
                                                                            <i className="fa fa-users"></i> Gold Player : <strong>{playerList.filter(p => p.CategoryID == 1).length}</strong>
                                                                        </span>
                                                                        <span className="margin-r10">
                                                                            <i className="fa fa-users"></i> Silver Player : <strong>{playerList.filter(p => p.CategoryID == 2).length}</strong>
                                                                        </span>
                                                                        <span className="margin-r10">
                                                                            <i className="fa fa-users"></i> Bronze Player : <strong>{playerList.filter(p => p.CategoryID == 3).length}</strong>
                                                                        </span>
                                                                        <span className="margin-r10">
                                                                            <i className="fa fa-users"></i> Total Player : <strong>{playerList.length}</strong>
                                                                        </span>
                                                                        <span className="">
                                                                            Funds : <strong><i className="fa fa-rupee-sign"></i> {fund}</strong>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="kt-form kt-form--label-right">
                        <div className="row align-items-center stickyFilter">
                            <div className="col-xl-12 order-2 order-xl-1">
                                <div className="row">
                                    <div className={`col-md-3 margin-t10`}>
                                        <select 
                                            className="form-control kt-selectpicker"
                                            value={selectedCategory || ''}
                                            onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            {(category && category.length > 0) && (
                                                category.map(c => (
                                                    <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                    <div className={`col-md-3 margin-t10 kt-margin-b-20-tablet-and-mobile`}>
                                        <div className="kt-input-icon kt-input-icon--left">
                                            <input                                      
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Player..."
                                                id="generalSearch"
                                                value={searchString || ''}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                >
                                            </input>
                                            <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                <span>
                                                    <i className="la la-search"></i>
                                                </span>
                                            </span>
                                            {searchString && (
                                                <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                    <span>
                                                        <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? (<Loading/>) : (
                            <table id="table-draft-pick" className="table table-bordered table-hover margin-b30">
                                <thead className="light-grey-background">
                                    <tr>
                                        <th>Player</th>
                                        <th className="text-center">Category</th>
                                        <th className="text-center">Handicap</th>
                                        <th className="text-center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPlayerList.length > 0 ? (
                                        filteredPlayerList.map((p) => (
                                            <tr className={`clickable`} key={p.ID} data-id={p.ID}>
                                                <td title={p.DisplayName}>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__pic">
                                                            {(p.Photo && p.Photo != '') ? <img src={p.Photo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.DisplayName.substring(0, 1)}</div>}
                                                        </div>
                                                        <div className="kt-user-card-v2__details text-clip">
                                                            <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                            <span className="kt-user-card-v2__desc text-clip">{p.MemberShipNo}</span>
                                                        </div>
                                                    </div>      
                                                </td>
                                                <td className="text-center">
                                                    <span className={`kt-badge kt-badge--${p.CategoryName == 'Gold' ? 'gold' : p.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                        {p.CategoryName}
                                                    </span>
                                                </td>
                                                <td className="text-center">{p.Handicap}</td>
                                                <td className="text-center"><i className="la la-rupee"></i>{p.Amount}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-center" colSpan={6}>No Player Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team