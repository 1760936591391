import React, { useEffect, useState } from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { getCategory, getSoldPlayerByTeamID } from '../api/playerAPI';
import { getAuthState } from '../../assets/scripts/login-util';
import Loading from '../global/loading';
import * as helper from '../global/helper';
import swal from 'sweetalert2'
const MyTeam = (props) => {

    const [category,                setCategory]                = useState([]);
    const [selectedCategory,        setSelectedCategory]        = useState(0);
    const [searchString,            setSearchString]            = useState('');
    const [filteredPlayerList,      setFilteredPlayerList]      = useState([])
    const [playerList,              setPlayerList]              = useState([]);
    const [loading,                 setLoading]                 = useState(false);
    const [fund,                    setFund]                    = useState(0)
    const user = getAuthState('user')

    useEffect(() => {
        props.title("My Team", "menuMyTeam");
        fetchCategory();
        fetchtSoldPlayerByTeamID();
    }, [])

    useEffect(() => {
        if (playerList.length > 0)
            filterPlayer();
        else
            setFilteredPlayerList([]);
            
    }, [playerList, searchString, selectedCategory]);


    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            setCategory(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const filterPlayer = async () => {

        let reports = playerList;
        if (selectedCategory && selectedCategory != 0) reports = reports.filter(f => f.CategoryID == selectedCategory);
        if (searchString === '') {
            setFilteredPlayerList(reports);
        }else{
            const matchedPolicies = reports.filter((p) => {
                let matchString = `${p.FirstName} ${p.LastName} ${p.MemberShipNo} ${p.CategoryName} ${p.Email}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPlayerList(matchedPolicies);
        }
    };

    const fetchtSoldPlayerByTeamID = async () => {
        try {
            setLoading(true);
            const { auctionPlayer, fund } = await getSoldPlayerByTeamID(user.teamID);
            setFund(fund)
            setPlayerList(auctionPlayer);             
            setLoading(false);
        } catch (error) {
            setLoading(false);
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile" >
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top">
                                <NavLink activeClassName="top-nav-active" to="/myTeam">
                                    My Team
                                </NavLink>
                            </nav>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <div className = "kt-portlet__head_subhead" >

                                        <span className="margin-r10">
                                            <i className="fa fa-users"></i> Gold Player : <strong>{playerList.filter(p => p.CategoryID == 1).length}</strong>
                                        </span>
                                        <span className="margin-r10">
                                            <i className="fa fa-users"></i> Silver Player : <strong>{playerList.filter(p => p.CategoryID == 2).length}</strong>
                                        </span>
                                        <span className="margin-r10">
                                            <i className="fa fa-users"></i> Bronze Player : <strong>{playerList.filter(p => p.CategoryID == 3).length}</strong>
                                        </span>
                                        <span className="margin-r10">
                                            <i className="fa fa-users"></i> Total Player : <strong>{playerList.length}</strong>
                                        </span>
                                        <span className="">
                                            Funds : <strong><i className="fa fa-rupee-sign"></i> {fund}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div className="kt-form kt-form--label-right">
                            <div className="row align-items-center stickyFilter">
                                <div className="col-xl-12 order-2 order-xl-1">
                                    <div className="row">
                                        <div className={`col-md-3`}>
                                            <div className="kt-form__group kt-form__group--inline ">
                                                <div className="kt-form__label"> <label htmlFor="statusFilter"> Category:</label> </div>
                                                <div className="kt-form__control">

                                                    <select 
                                                        className="form-control kt-selectpicker"
                                                        value={selectedCategory || ''}
                                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                                    >
                                                        {(category && category.length > 0) && (
                                                            category.map(c => (
                                                                <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                            ))
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-md-3 kt-margin-b-20-tablet-and-mobile`}>
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input                                      
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search Player..."
                                                    id="generalSearch"
                                                    value={searchString || ''}
                                                    onChange={(e) => setSearchString(e.target.value)}
                                                    >
                                                </input>
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                                    <span>
                                                        <i className="la la-search"></i>
                                                    </span>
                                                </span>
                                                {searchString && (
                                                    <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                                        <span>
                                                            <i className="la la-close" onClick={() => setSearchString('')}></i>
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading ? (<Loading/>) : (
                                filteredPlayerList.length > 0 ? (
                                    <>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                                    <table className="kt-datatable__table">
                                                        <thead
                                                            className="kt-datatable__head"
                                                            style={{ display: 'block' }}>
                                                            <tr className="kt-datatable__row block-row">
                                                                <th className="kt-datatable__cell text-clip" width="40%">
                                                                    <span className="padding-l10">Player</span>
                                                                </th>
                                                                <th className="kt-datatable__cell text-clip" width="15%">
                                                                    <span>Mobile</span>
                                                                </th>
                                                                <th className="kt-datatable__cell text-clip" width="20%">
                                                                    <span className="padding-l10">Amount</span>
                                                                </th>
                                                                <th className="kt-datatable__cell text-clip" width='20%'>
                                                                    <span>Membership No.</span>
                                                                </th>
                                                                <th className="kt-datatable__cell text-clip" width='25%'>
                                                                    <span>Category</span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div 
                                                    className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                                    onScroll={helper.setStickyHeader}>
                                                    <table className="kt-datatable__table table-striped">
                                                        <tbody
                                                            className="kt-datatable__body"
                                                            style={{ display: 'block', minHeight:'40vh'}}>
                                                            {filteredPlayerList.map((p, i) => (
                                                                <tr
                                                                    key={i}
                                                                    className="kt-datatable__row block-row">                                                   
                                                                    <td width='40%' className="kt-datatable__cell text-clip">
                                                                        <div className="kt-user-card-v2">
                                                                            <div className="kt-user-card-v2__pic">
                                                                                {p.Photo && p.Photo !== '' ? (
                                                                                    <img src={p.Photo} height="40" width="40" alt="banner-image" />
                                                                                ) : (
                                                                                    <div className="kt-badge kt-badge--xl kt-badge--info">
                                                                                        {p.DisplayName.substring(0, 1)}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="kt-user-card-v2__details text-clip">
                                                                                <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                                <span className="kt-user-card-v2__desc text-clip">Handicap: {p.Handicap}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td width='15%' className="kt-datatable__cell text-clip">
                                                                        {p.Mobile}
                                                                    </td>
                                                                    <td width='20%' className="kt-datatable__cell text-clip">
                                                                        <i className="la la-rupee"></i>
                                                                        {p.Amount}
                                                                    </td>
                                                                    <td width='20%' className="kt-datatable__cell text-clip">{p.MemberShipNo}</td>
                                                                    <td width='25%' className="text-clip">
                                                                        <span className={`kt-badge kt-badge--${p.CategoryName == 'Gold' ? 'gold' : p.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                                            {p.CategoryName}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="padding-20">
                                        <div className="empty-image-container">
                                            <h5 className="margin-t20">No Player Found</h5>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyTeam