import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useParams } from 'react-router-dom';
import swal from 'sweetalert2';
import Admin from './Admin';
import Login from '../components/login/login';
import { getAuthState } from '../assets/scripts/login-util';
import { redirectToLogout } from "../components/api/common";
import '../containers/App.css'
import Team from './Team';

const App = () => {

    const [auth,      setAuth]               = useState(getAuthState('bool'));

    useEffect(() => {
        const authorized = getAuthState('bool');
        if (authorized === 'Expired') redirectToLogout()
        else {
           setAuth(authorized);
        }
    }, []);

    const authHandler = async () => {
        const authorized = getAuthState('bool');
        authorized === 'Expired' ? setAuth(false) : setAuth(authorized);
    }

    return (
        <BrowserRouter>
                {auth ? (
                    <Switch>
                        <Route path="/team-player/:id" exact>
                            <Team/>
                        </Route>
                        <Admin />
                    </Switch>
                ) : (
                    <Switch>
                        <Route path="/login" exact>
                            <Login onLogin={authHandler} />
                        </Route>
                        <Route path="/team-player/:id" exact>
                            <Team/>
                        </Route>
                        <Redirect to="/login" />
                    </Switch>
                )}           
        </BrowserRouter>
    );

};

export default App;